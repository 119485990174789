import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

const scrollArea = document.querySelector('#scrollArea');

Alpine.data('reelsNav', () => ({
    nextReel () {
        scrollArea.scrollBy({ top: 100, behavior: 'smooth' });
    },

    prevReel () {
        scrollArea.scrollBy({ top: -100, behavior: 'smooth' });
    },
}));
