import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.data('reelsplayer', (id) => ({
    id: id,
    muted: true,
    player: null,

    init () {
        if (window.frop_reels_player_muted === undefined)
            window.frop_reels_player_muted = true;
        if (!window.frop_players)
            window.frop_players = [];
        if (!window.frop_players[this.id])
            window.frop_players[this.id] = this.jwp_init_call_back.bind(this);
        else
            this.jwp_init_call_back();
    },

    jwp_init_call_back () {
        this.player = window.frop_players[this.id];
        this.muted = window.frop_reels_player_muted;

        if (this.player.getMute() && !this.muted) this.unmute();
        if (!this.player.getMute() && this.muted) this.mute();
        this.player.on('mute', (e) => {
            this.muted = e.mute;
            window.frop_reels_player_muted = e.mute;
        });
    },

    play () {
        if (!this.player) return;
        this.player.seek(0);
        if (!window.frop_reels_player_muted) this.unmute();
        this.player.play();
    },

    pause () {
        if (!this.player) return;
        this.player.pause();
    },

    mute () {
        if (!this.player) return;
        this.player.setMute(true);
    },

    unmute () {
        if (!this.player) return;
        this.player.setMute(false);
        this.player.setVolume(100);
    },

    share (title, url) {
        if (navigator.share) {
            const shareData = {
                title,
                url,
            }
            navigator.share(shareData);
        } else {
            console.log('Share not supported on this browser, do it the old way.', url);
        }
    },
}));
